<template>
    <div class="page page--about about">
        <div class="page__block" v-for="(item,index) in Object.keys($i18n.messages[$i18n.locale].about).length/2"
             :key="item">
            <h2>{{$t('about.title'+(index + 1))}}</h2>
            <p>{{$t('about.description'+(index+1))}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About",

        mounted () {
            window.scrollTo(0, 0);
        }
    }
</script>
